import React from 'react';

import Layout from '../components/Layout';
import ContactForm from '../components/ContactForm';

const IndexPage = () => (
  <Layout>
    <h1>À céder / vendre :</h1>
    <ul>
      <li>stcyp.net</li>
      <li>stcyp.fr</li>
    </ul>
    <p>
      Si vous êtes intéressés par l'achat d'un ou plusieurs domaines,<br />
      merci de nous contacter grâce au formulaire suivant :
    </p>
    <ContactForm />
  </Layout>
);

export default IndexPage;

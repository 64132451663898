import React from 'react';

import { form } from './ContactForm.module.css';

const ContactForm = () => (
  <form
    className={form}
    name="contact"
    method="POST"
    data-netlify="true"
    action="/form-success"
  >
    <p>
      <label>Nom :<br />
        <input type="text" name="name" />
      </label>
    </p>
    <p>
      <label>Email :<br />
        <input type="email" name="email" />
      </label>
    </p>
    <p>
      <label>Message :<br />
        <textarea name="message" />
      </label>
    </p>
    <p>
      <button type="submit">Envoyer</button>
    </p>
  </form>
);

export default ContactForm;
